import React, { useState, useRef, forwardRef, useEffect } from "react";
import {
    StyledPeriodContainer,
    StyledPeriodFieldGroup,
    StyledPeriodField,
    StyledPeriodDivider,
    StyledDatePicker,
    StyledTimePicker,
    CalendarIconStyle,
    ClockIconStyle
} from "./DetailCustomPeriodStyles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { TextField } from "@mui/material";
import ArrowRightIcon from "@/presentation/assets/icone/arrow-right-icon.svg";
import CalendarIcon from "@/presentation/assets/icone/calendar-icon.svg";
import ClockIcon from "@/presentation/assets/icone/clock-icon.svg";

const CustomDateInput = forwardRef<HTMLInputElement, any>((props, ref) => (
    <input {...props} ref={ref} />
));
CustomDateInput.displayName = "CustomDateInput";

type DetailCustomPeriodProps = {
    blocked_text_start_date?: string | null;
    blocked_text_end_date?: string | null;
    onChangeStartDate: (value: string) => void;
    onChangeEndDate: (value: string) => void;
    title_message?: string;
};

function parseDateString(dateString?: string | null): Date | null {
    if (!dateString || dateString.trim() === "") return null;
    const [datePart, timePart] = dateString.split(" ");
    if (!datePart || !timePart) return null;
    const [year, month, day] = datePart.split("-").map(Number);
    const [hour, minute, second] = timePart.split(":").map(Number);
    if (
        !year ||
        !month ||
        !day ||
        hour === undefined ||
        minute === undefined ||
        second === undefined
    ) {
        return null;
    }
    return new Date(year, month - 1, day, hour, minute, second);
}

export const DetailCustomPeriod: React.FC<DetailCustomPeriodProps> = ({
    blocked_text_start_date,
    blocked_text_end_date,
    onChangeStartDate,
    onChangeEndDate,
    title_message = ""
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);

    const [startTimeOpen, setStartTimeOpen] = useState(false);
    const [endTimeOpen, setEndTimeOpen] = useState(false);
    const [startDateOpen, setStartDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);

    const startDateRef = useRef<HTMLInputElement>(null);
    const endDateRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const parsedStart = parseDateString(blocked_text_start_date);
        const parsedEnd = parseDateString(blocked_text_end_date);

        const defaultDate = new Date();

        const newStartDate = parsedStart
            ? new Date(parsedStart.getFullYear(), parsedStart.getMonth(), parsedStart.getDate())
            : defaultDate;
        const newEndDate = parsedEnd
            ? new Date(parsedEnd.getFullYear(), parsedEnd.getMonth(), parsedEnd.getDate())
            : defaultDate;

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        const newStartTime = parsedStart
            ? new Date(1970, 0, 1, parsedStart.getHours(), parsedStart.getMinutes(), parsedStart.getSeconds())
            : new Date(1970, 0, 1, defaultDate.getHours(), defaultDate.getMinutes(), defaultDate.getSeconds());
        const newEndTime = parsedEnd
            ? new Date(1970, 0, 1, parsedEnd.getHours(), parsedEnd.getMinutes(), parsedEnd.getSeconds())
            : new Date(1970, 0, 1, defaultDate.getHours(), defaultDate.getMinutes(), defaultDate.getSeconds());

        setStartTime(newStartTime);
        setEndTime(newEndTime);
    }, [blocked_text_start_date, blocked_text_end_date]);

    const handleStartDateChange = (date: Date | null) => {
        const finalDate = date || new Date();
        setStartDate(finalDate);

        updateDateTime(finalDate, startTime, onChangeStartDate);
    };

    const handleStartTimeChange = (time: Date | null) => {
        const finalTime = time || new Date(1970, 0, 1);
        setStartTime(finalTime);

        updateDateTime(startDate, finalTime, onChangeStartDate);
    };

    const handleEndDateChange = (date: Date | null) => {
        const finalDate = date || new Date();
        setEndDate(finalDate);

        updateDateTime(finalDate, endTime, onChangeEndDate);
    };

    const handleEndTimeChange = (time: Date | null) => {
        const finalTime = time || new Date(1970, 0, 1);
        setEndTime(finalTime);

        updateDateTime(endDate, finalTime, onChangeEndDate);
    };

    const updateDateTime = (date: Date | null, time: Date | null, callback: (value: string) => void) => {
        if (!date || !time) {
            callback("");
            return;
        }
        const combined = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes(),
            time.getSeconds()
        );
        const formatted = `${combined.getFullYear()}-${String(combined.getMonth() + 1).padStart(2, "0")}-${String(
            combined.getDate()
        ).padStart(2, "0")} ${String(combined.getHours()).padStart(2, "0")}:${String(
            combined.getMinutes()
        ).padStart(2, "0")}:${String(combined.getSeconds()).padStart(2, "0")}`;

        callback(formatted);
    };

    return (
        <StyledPeriodContainer>
            <h3 dangerouslySetInnerHTML={{ __html: title_message }} />
            <StyledPeriodFieldGroup>
                <StyledPeriodField>
                    <span>Início</span>
                    <StyledDatePicker>
                        <CalendarIconStyle
                            onClick={() => {
                                setStartDateOpen(true);
                                startDateRef.current?.focus();
                            }}
                        >
                            <img src={CalendarIcon} alt="Calendar Icon" />
                        </CalendarIconStyle>
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            onClickOutside={() => setStartDateOpen(false)}
                            open={startDateOpen}
                            dateFormat="dd/MM/yyyy"
                            locale="ptBR"
                            customInput={
                                <CustomDateInput
                                    ref={startDateRef}
                                    onFocus={() => setStartDateOpen(true)}
                                />
                            }
                        />
                    </StyledDatePicker>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <StyledTimePicker>
                            <ClockIconStyle onClick={() => setStartTimeOpen(true)}>
                                <img src={ClockIcon} alt="Clock Icon" />
                            </ClockIconStyle>
                            <TimePicker
                                value={startTime}
                                onChange={handleStartTimeChange}
                                open={startTimeOpen}
                                onOpen={() => setStartTimeOpen(true)}
                                onClose={() => setStartTimeOpen(false)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{ ...params.InputProps, endAdornment: null }}
                                    />
                                )}
                            />
                        </StyledTimePicker>
                    </LocalizationProvider>
                </StyledPeriodField>

                <StyledPeriodDivider>
                    <img src={ArrowRightIcon} alt="Arrow Right Icon" />
                </StyledPeriodDivider>

                <StyledPeriodField>
                    <span>Fim</span>
                    <StyledDatePicker>
                        <CalendarIconStyle
                            onClick={() => {
                                setEndDateOpen(true);
                                endDateRef.current?.focus();
                            }}
                        >
                            <img src={CalendarIcon} alt="Calendar Icon" />
                        </CalendarIconStyle>
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            onClickOutside={() => setEndDateOpen(false)}
                            open={endDateOpen}
                            dateFormat="dd/MM/yyyy"
                            locale="ptBR"
                            customInput={
                                <CustomDateInput
                                    ref={endDateRef}
                                    onFocus={() => setEndDateOpen(true)}
                                />
                            }
                        />
                    </StyledDatePicker>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <StyledTimePicker>
                            <ClockIconStyle onClick={() => setEndTimeOpen(true)}>
                                <img src={ClockIcon} alt="Clock Icon" />
                            </ClockIconStyle>
                            <TimePicker
                                value={endTime}
                                onChange={handleEndTimeChange}
                                open={endTimeOpen}
                                onOpen={() => setEndTimeOpen(true)}
                                onClose={() => setEndTimeOpen(false)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{ ...params.InputProps, endAdornment: null }}
                                    />
                                )}
                            />
                        </StyledTimePicker>
                    </LocalizationProvider>
                </StyledPeriodField>
            </StyledPeriodFieldGroup>
        </StyledPeriodContainer>
    );
};
